import React, { PropsWithChildren } from 'react'

import { useQuery, QueryKey, QueryObserverResult } from '@tanstack/react-query'

import { AnamnesisDetails } from '../@types/Anamnesis'
import { CalmasterAppointment } from '../@types/Appointment'
import keyBy from '../helpers/keyBy'
import { mockQueryLoadingResult } from '../helpers/reactQuery'
import { minutesToMs } from '../helpers/time'
import withQueryParams from '../helpers/withQueryParams'
import api from '../services/api'

import { useCurrentClinicProvider } from './CurrentClinicProvider'
import { useUpcomingAppointmentsContext } from './UpcomingAppointmentsProvider'

export type AnamnesisDetialsContext = QueryObserverResult<Record<string, AnamnesisDetails>, unknown>

const initialState = mockQueryLoadingResult({})

export const Context = React.createContext<AnamnesisDetialsContext>(initialState)

export const QueryKeyContext = React.createContext<QueryKey>([])

const keyByPatients = keyBy<AnamnesisDetails>(({ patientReferenceId }: AnamnesisDetails) => patientReferenceId)

const AnamnesisDetailsProvider = ({ children }: PropsWithChildren) => {
  const { appointments } = useUpcomingAppointmentsContext()
  const { selectedClinic } = useCurrentClinicProvider()
  const patientReferenceIds = appointments
    .map((appointment: CalmasterAppointment) => appointment.patient?.referenceId)
    .filter((id) => id !== undefined) as string[]

  const queryKey = ['anamnesis-details', patientReferenceIds]
  const service = selectedClinic?.featureFlags?.ANA_VIEW_ENABLED
    ? api.ana.getPatientsAnamnesis
    : () => [
        {
          patientReferenceId: '',
          activeCodes: [],
          stepsCompleted: [],
        },
      ]

  const anamnesisDetails = useQuery({
    queryKey,
    queryFn: withQueryParams(service),
    refetchInterval: minutesToMs(2),
    select: keyByPatients,
    // arrays are objects and according to the spec ToBoolean always converts objects to true
    enabled: Boolean(patientReferenceIds?.length),
    notifyOnChangeProps: ['data'],
  })

  return (
    <Context.Provider value={anamnesisDetails}>
      <QueryKeyContext.Provider value={queryKey}>{children}</QueryKeyContext.Provider>
    </Context.Provider>
  )
}

export const useAnamnesisDetails = () => {
  const context = React.useContext(Context)

  if (context === undefined) throw new Error('useAnamnesisDetails must be used within a AnamnesisDetailsProvider')

  return context
}

export const useAnamnesisDetailsQueryKey = () => {
  const context = React.useContext(QueryKeyContext)

  if (context === undefined)
    throw new Error('useAnamnesisDetailsQueryKey must be used within a AnamnesisDetailsProvider')

  return context
}

export default AnamnesisDetailsProvider
