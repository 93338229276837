export enum ClinicType {
  DENTIST = 'DENTIST',
  GP = 'GP',
  GYNAECOLOGIST = 'GYNAECOLOGIST',
  UROLOGIST = 'UROLOGIST',
  UNKNOWN = 'UNKNOWN',
}

type ClinicFeatureFlags = {
  ANA_VIEW_ENABLED: boolean
  OPERATOR_ENABLED: boolean
  TWO_WAY_MESSAGING_ENABLED: boolean
  TO_DO_ENABLED: boolean
  PATIENT_COMMUNICATION_ENABLED: boolean
  HEALTH_ASSISTANT_ENABLED: boolean
  ADDITIONAL_INSURANCE_OFFERS_ENABLED: boolean
}

export interface Clinic {
  referenceId: string
  name: string
  type?: ClinicType
  featureFlags: ClinicFeatureFlags
}
