import React, { createContext, useContext, ReactNode, useMemo } from 'react'

import { useParams } from 'react-router'

import { QueryObserverResult } from '@tanstack/react-query'

import { Conversation } from '../@types/Conversation'
import { ParamTypes } from '../@types/RouteParams'
import useConversations from '../hooks/useConversations'
import useConversationSubscription from '../subscriptions/useConversationsSubscription'

import { useCurrentClinicProvider } from './CurrentClinicProvider'

type State = {
  active: Conversation[]
  archived: Conversation[]
  activeConversationsQuery: Omit<QueryObserverResult, 'data'>
  archivedConversationsQuery: Omit<QueryObserverResult, 'data'>
}

type ConversationsProviderProps = { children: ReactNode }

const ConversationsContext = createContext<State | undefined>(undefined)

export const ConversationsProvider: React.FC<ConversationsProviderProps> = ({ children }) => {
  const { selectedClinic } = useCurrentClinicProvider()
  const conversationsEnabled = !!selectedClinic?.featureFlags?.TWO_WAY_MESSAGING_ENABLED
  const { sideNav } = useParams<ParamTypes>()
  const isCallsPage = sideNav === 'sms'
  const disabled = !isCallsPage || !conversationsEnabled

  const {
    conversations: { data: activeConversationsData, ...activeConversationsQuery },
    queryKey: activeQueryKey,
  } = useConversations({
    isArchived: false,
    disabled: !conversationsEnabled,
  })

  const {
    conversations: { data: archivedConversationsData, ...archivedConversationsQuery },
  } = useConversations({
    isArchived: true,
    disabled,
  })

  const conversations = useMemo(
    () => ({
      active: activeConversationsData ?? [],
      archived: archivedConversationsData ?? [],
      activeConversationsQuery,
      archivedConversationsQuery,
    }),
    [activeConversationsData, activeConversationsQuery, archivedConversationsData, archivedConversationsQuery]
  )

  useConversationSubscription({ queryKey: activeQueryKey, disabled: !selectedClinic?.referenceId || disabled })

  return <ConversationsContext.Provider value={conversations}>{children}</ConversationsContext.Provider>
}

export const useConverationsContext = () => {
  const context = useContext(ConversationsContext)

  if (context === undefined) {
    throw new Error('useConverations must be used within a ConversationsProvider')
  }

  return context
}
