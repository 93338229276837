import React from 'react'

import { makeStyles } from '@material-ui/core'
import TabPanel from '@material-ui/lab/TabPanel'

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
})

type Props = {
  children?: React.ReactNode
  value: string
}

const StyledTabPanel = ({ value, children }: Props) => {
  const classes = useStyles()

  return (
    <TabPanel
      value={value}
      classes={{
        root: classes.root,
      }}
    >
      {children}
    </TabPanel>
  )
}

export default StyledTabPanel
