import React from 'react'

import { useCallTasks } from '../../providers/CallTasksProvider'
import { useConverationsContext } from '../../providers/ConversationsProvider'
import { useCurrentClinicProvider } from '../../providers/CurrentClinicProvider'

import { SideNav as SideNavComponent } from './SideNav'
import { getUnreadConversations } from './SideNav.helpers'

type Props = {
  isOpen?: boolean
}

const SideNav = ({ isOpen }: Props) => {
  const { selectedClinic } = useCurrentClinicProvider()

  const callsEnabled = !!selectedClinic?.featureFlags?.OPERATOR_ENABLED
  const conversationsEnabled = !!selectedClinic?.featureFlags?.TWO_WAY_MESSAGING_ENABLED
  /**
   * =====
   * Calls
   * =====
   */
  const calls = useCallTasks()
  /**
   * =====
   * END Calls
   * =====
   */

  /**
   * =====
   * SMS Conversations
   * =====
   */

  const conversations = useConverationsContext()
  const unreadConversationsCount = getUnreadConversations(conversations.active)

  /**
   * =====
   * END SMS Conversations
   * =====
   */

  return (
    <SideNavComponent
      isOpen={isOpen}
      openCallTasks={calls.open.tasks}
      solvedCallTasks={calls.solved.tasks}
      callsEnabled={callsEnabled}
      conversationsEnabled={conversationsEnabled}
      unreadConversationsCount={unreadConversationsCount}
      openCallCount={calls.open.count}
    />
  )
}

export default SideNav
