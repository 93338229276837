import React, { lazy, useCallback, useEffect, useMemo, useState, useTransition, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { AppointmentPatient, DATE_FORMATS, GenderType, insertIf, InsuranceType, Language } from '@dentalux/common'
import { useCredentials } from '@dentalux/security'
import {
  TodoList,
  TodoDetails,
  OpenAppointmentsList,
  OpenAppointmentsListRef,
  CallManagement,
  AppointmentForm,
  PhoneType,
} from '@dentalux/ui-library-core'
import { EmptyCalendarIcon, EventNoteIcon, ListIcon, MessageBubbleIcon, PhoneIcon } from '@dentalux/ui-library-core'

import TabContext from '@material-ui/lab/TabContext'

import { Box, Button, ButtonProps, Tab, Tabs } from '@mui/material'
import clsx from 'clsx'
import { compareAsc, format } from 'date-fns'
import moment from 'moment'
import { useSnackbar } from 'notistack'

import { AnalyticsLabel, AnalyticsCategory } from '../../@types/Analytics'
import { CallTask, CallTaskStates } from '../../@types/CallTask'
import { ParamTypes } from '../../@types/RouteParams'
import { ReactComponent as EmployeeIcon } from '../../assets/icons/employee_icon.svg'
import { setLocalStorage } from '../../helpers/setLocalStorage'
import switchFn from '../../helpers/switch'
import useConversationByPhone from '../../hooks/useConversationByPhone'
import usePostOutgoingCall from '../../hooks/usePostOutgoingCall'
import { useAppointmentUndoDialogContext } from '../../providers/AppointmentUndoDialogProvider'
import { useBitComponentsContext } from '../../providers/BitComponentsProvider'
import { useCalendarSettingsContext } from '../../providers/CalendarSettingsProvider'
import { startCreateConversation, useCreateConversationDispatch } from '../../providers/CreateConversationProvider'
import { useCurrentClinicProvider } from '../../providers/CurrentClinicProvider'
import { useEmployeesFiltersContext } from '../../providers/EmployeesFiltersProvider'
import { useFeatureFlags } from '../../providers/FeatureFlags'
import { useLanguageContext } from '../../providers/LanguageProvider'
import { useObfuscationProvider } from '../../providers/ObfuscationProvider'
import { useOpenAppointmentsEmployeesFiltersContext } from '../../providers/OpenAppointmentsEmployeesFiltersProvider'
import {
  usePatientDetailsModalDispatch,
  usePatientDetailsModalState,
  setActivePatient,
} from '../../providers/PatientDetailsModalProvider'
import { useSideNavigationContext } from '../../providers/SideNavigationProvider'
import { useTodoListTasksProvider } from '../../providers/TodoListTasksProvider'
import { sendAnalyticsEvent } from '../../services/analytics'
import { viewTodoList } from '../../services/featureFlags'
// TODO: Remove after all new themes will be merged
import { UiLibraryThemeProvider } from '../../theme/uiTheme'
import PractitionerDropdown from '../PractitionerDropdown'
import SuspendedComponent from '../SuspendedComponent'

import CallNotification from './components/CallNotification/CallNotification'
import TabPanel from './components/TabPanel'
import { getCallManagementLabels, getTodoTabLabels, getOpenAppointmentsLabels } from './SideNav.helpers'
import { useStyles } from './SideNav.styles'
import * as S from './SideNav.styles'

const WaitingRoomStatic = lazy(() => import('../WaitingRoom'))
const ConversationManagement = lazy(() => import('../ConversationManagement'))

type Props = {
  isOpen?: boolean
  openCallTasks?: CallTask[]
  solvedCallTasks?: CallTask[]
  callsEnabled?: boolean
  conversationsEnabled?: boolean
  unreadConversationsCount?: number
  openCallCount?: number
}

export enum TabNames {
  PATIENTS = 'PATIENTS',
  SMS = 'SMS',
  CALLS = 'CALLS',
  TODO_LIST = 'TODO_LIST',
  OPEN_APPOINTMENTS = 'OPEN_APPOINTMENTS',
}

const emptyPatientWithPhone = {
  email: '',
  firstName: '',
  lastName: '',
  referenceId: '',
  birthDate: format(new Date(), DATE_FORMATS.ISO_SHORT),
  genderType: GenderType.FEMALE,
  insuranceType: InsuranceType.PRIVATE_INSURANCE,
}

export const SideNav = ({
  isOpen,
  openCallTasks = [],
  solvedCallTasks = [],
  callsEnabled,
  conversationsEnabled,
  unreadConversationsCount = 0,
  openCallCount = 0,
}: Props) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const featureFlags = useFeatureFlags()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { queryDetails, todos, isOpen: isTodosPanelOpen } = useTodoListTasksProvider()
  const { authentication, token } = useCredentials()
  const { selectedClinic } = useCurrentClinicProvider()
  const { selectedAppointment, setSelectedAppointment, selectedPatient, setSelectedPatient } =
    useSideNavigationContext()

  const openAppointmentsRef = useRef<OpenAppointmentsListRef>(null)

  const { filteredSelectedEmployees } = useEmployeesFiltersContext()
  const { toggleDetails: toggleAppointmentUndoDialog } = useAppointmentUndoDialogContext()

  const { currentLanguage } = useLanguageContext()

  const { calendarRef, setPatientDetailsInitialValues } = useBitComponentsContext()
  const { setCurrentDate } = useCalendarSettingsContext()

  const { activePatientReferenceId } = usePatientDetailsModalState()

  const canViewTodoList = viewTodoList(selectedClinic, authentication)

  const { conversation, isFetching } = useConversationByPhone(
    selectedAppointment?.patient?.phoneNumber ?? selectedPatient?.phoneNumber
  )

  const { view, date, sideNav, clinicReferenceId } = useParams<ParamTypes>()
  const { pathname } = useLocation()

  const isSearchPage = pathname.endsWith('search')

  const [patientDetailRefId, setPatientDetailRefId] = useState('')
  const [selectedTab, setSelectedTab] = useState<TabNames>((sideNav?.toUpperCase() as TabNames) ?? TabNames.PATIENTS)

  const { mutateAsync: postOutgoingCall } = usePostOutgoingCall({
    onError: () => {
      enqueueSnackbar(t('backend.error.generic_title'), {
        variant: 'error',
      })
    },
  })

  const handleCallPatientClick = async (patientReferenceId: string, data: PhoneType) => {
    await postOutgoingCall({ patientReferenceId, phone: data })
  }

  const handleOpenInCalendar = (data?: TodoDetails) => {
    if (!data) return
    const appointmentDate = (data.appointments ?? []).map(({ start }) => new Date(start)).sort(compareAsc)[0]
    const patientDetails = data.patient

    if (!appointmentDate && patientDetails) {
      const initialData = {
        employeeReferenceId: data?.costPlan?.employee?.referenceId,
        patient: {
          ...patientDetails,
          insuranceType: InsuranceType.PRIVATE_INSURANCE,
          phoneNumber: '',
          email: '',
          referenceId: patientDetails.referenceId as string,
          genderType: patientDetails.gender as GenderType,
          birthDate: patientDetails.birthday,
        },
      }

      if (isSearchPage) {
        setPatientDetailsInitialValues(initialData)
      } else {
        calendarRef?.toggleAppointmentDialog(initialData)
      }

      return
    }

    if (appointmentDate) {
      history.push(
        `/calendar/${view ?? 'day'}/${format(
          new Date(appointmentDate),
          DATE_FORMATS.ISO_SHORT
        )}/todo_list/${clinicReferenceId}`
      )

      setLocalStorage('calendarParams', { view, date, clinicReferenceId })
      setCurrentDate(moment(appointmentDate))
    }
  }

  const patientDetailsModalDispatch = usePatientDetailsModalDispatch()
  const createConversationDispatch = useCreateConversationDispatch()
  const openAppointmentsEmployeesContext = useOpenAppointmentsEmployeesFiltersContext()
  const { isObfuscated } = useObfuscationProvider()

  const [, startTransition] = useTransition()

  const isIncomingCallsEnabled = featureFlags.incomingCalls
  const isRinging = (callTask: CallTask) => callTask.currentState === CallTaskStates.Open_Ringing
  const isOngoing = (callTask: CallTask) => callTask.currentState === CallTaskStates.Open_InProgress
  const isCallTasksActive = useCallback((callTask: CallTask) => isRinging(callTask) || isOngoing(callTask), [])
  const selectedTabFromUrl = sideNav ?? selectedTab

  const patientDetailsOpen = Boolean(activePatientReferenceId)

  const shouldRenderPopup =
    !patientDetailsOpen && isIncomingCallsEnabled && (!featureFlags.hasHQStatus || featureFlags.isHQAdmin)

  const callManagementLabels = useMemo(() => getCallManagementLabels(t), [t])
  const todoTabLabels = useMemo(() => getTodoTabLabels(t), [t])

  const openAppointmentsLabels = useMemo(() => getOpenAppointmentsLabels(t), [t])

  const TABS = useMemo(
    () => [
      {
        icon: <EventNoteIcon />,
        value: TabNames.PATIENTS,
        testId: 'patients',
      },
      ...insertIf(!!conversationsEnabled, {
        icon: <MessageBubbleIcon badge={unreadConversationsCount} />,
        value: TabNames.SMS,
        testId: 'sms',
      }),
      ...insertIf(!!callsEnabled, {
        icon: <PhoneIcon badge={openCallCount} />,
        value: TabNames.CALLS,
        testId: 'calls',
      }),
      ...insertIf(canViewTodoList, {
        icon: <ListIcon badge={todos} />,
        value: TabNames.TODO_LIST,
        testId: 'todo-list',
      }),
      {
        icon: <EmptyCalendarIcon />,
        value: TabNames.OPEN_APPOINTMENTS,
        testId: 'open-appointments',
      },
    ],
    [conversationsEnabled, unreadConversationsCount, canViewTodoList, openCallCount, callsEnabled, todos]
  )

  const handleTabChange = useCallback(
    (_: unknown, value: TabNames) => {
      startTransition(() => {
        setSelectedAppointment(null)
        setSelectedPatient(null)
        setSelectedTab(value)
      })

      sendAnalyticsEvent(`${value.toLowerCase()}_tab`)
      history.push(`/calendar/${view}/${date}/${value.toLowerCase()}/${clinicReferenceId}`)

      if (value === TabNames.TODO_LIST) queryDetails?.refetch()

      setLocalStorage('calendarParams', {
        view,
        date,
        sideNav: value.toLowerCase(),
        clinicReferenceId,
      })
    },
    [history, view, date, clinicReferenceId, setSelectedAppointment]
  )

  const closeAllActiveCalls = useCallback(
    (id: string) => {
      closeSnackbar(`${id}_Open_InProgress`)
      closeSnackbar(`${id}_Open_Ringing`)
    },
    [closeSnackbar]
  )

  const activeCallTasks = useMemo(
    () => openCallTasks.filter((callTask) => isCallTasksActive(callTask)),
    [openCallTasks, isCallTasksActive]
  )

  const inactiveCallTasks = useMemo(
    () => openCallTasks.filter((callTask) => !isCallTasksActive(callTask)),
    [openCallTasks, isCallTasksActive]
  )

  const callTasksToBeCleanedUp = useMemo(
    () => (solvedCallTasks?.length > 0 ? [...inactiveCallTasks, ...solvedCallTasks] : inactiveCallTasks),
    [inactiveCallTasks, solvedCallTasks]
  )

  const handleCallCardClick = (patientReferenceId: string) => {
    patientDetailsModalDispatch(setActivePatient(patientReferenceId))
  }

  const handleAppointmentDialogClose = (formData?: AppointmentForm | null) => {
    toggleAppointmentUndoDialog?.(formData)
  }

  const handleNewAppointmentClick = (callTask: unknown) => {
    // TODO: Export CallTask type
    const data = callTask as CallTask

    if (isIncomingCallsEnabled) {
      const initialData = {
        employeeReferenceId: null,
        patient: {
          ...emptyPatientWithPhone,
          phoneNumber: data?.caller?.phoneNumber,
        },
      }

      if (isSearchPage) {
        setPatientDetailsInitialValues(data)
      } else {
        calendarRef?.toggleAppointmentDialog(initialData)
      }
    }
  }

  const handlePatientClick = (patient: AppointmentPatient) => {
    if (patient.referenceId) patientDetailsModalDispatch(setActivePatient(patient.referenceId))
  }

  const getSnackbar = useCallback(
    (callTask: CallTask) => {
      const onNewAppointment = () => {
        if (isIncomingCallsEnabled) {
          const data = {
            employeeReferenceId: null,
            patient: {
              ...emptyPatientWithPhone,
              phoneNumber: callTask?.caller?.phoneNumber,
            },
          }

          if (isSearchPage) {
            setPatientDetailsInitialValues(data)
          } else {
            calendarRef?.toggleAppointmentDialog(data)
          }
        }

        sendAnalyticsEvent(AnalyticsLabel.caller_detection_new_appointment, AnalyticsCategory.calmaster)
      }

      const onPatientDetails = (patientReferenceId: string) => {
        handleSelectPatient(patientReferenceId)
        sendAnalyticsEvent(AnalyticsLabel.caller_detection_patient_information, AnalyticsCategory.calmaster)
      }

      const handleSelectPatient = (id: string) => {
        patientDetailsModalDispatch(setActivePatient(id))
        setPatientDetailRefId(callTask.referenceId)
      }

      const content = (
        <CallNotification
          id={callTask.referenceId}
          callTask={callTask}
          onNewAppointment={onNewAppointment}
          onPatientDetails={onPatientDetails}
        />
      )

      const snack = enqueueSnackbar(callTask.currentState, {
        variant: 'default',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        content,
        key: `${callTask.referenceId}_${callTask.currentState}`,
        preventDuplicate: true,
        persist: true,
      })

      return snack
    },
    [calendarRef, enqueueSnackbar, isIncomingCallsEnabled, patientDetailsModalDispatch]
  )

  const handleOpenSmsTab = (patient: AppointmentPatient) => setSelectedPatient(patient)

  const handleOpenAppointmentDialog = () => {
    openAppointmentsRef.current?.toggleOpenAppointmentDialog({}, true)
  }

  useEffect(() => {
    closeAllActiveCalls(patientDetailRefId)
    activeCallTasks.forEach((callTask) => {
      if (shouldRenderPopup) getSnackbar(callTask)
      if (isOngoing(callTask)) closeSnackbar(`${callTask.referenceId}_Open_Ringing`)
    })

    return () => setPatientDetailRefId('')
  }, [
    activeCallTasks,
    shouldRenderPopup,
    patientDetailRefId,
    closeSnackbar,
    closeAllActiveCalls,
    getSnackbar,
    isTodosPanelOpen,
  ])

  useEffect(() => {
    callTasksToBeCleanedUp.forEach((callTask) => closeAllActiveCalls(callTask.referenceId))
  }, [closeAllActiveCalls, callTasksToBeCleanedUp])

  useEffect(() => {
    if ((selectedAppointment || selectedPatient) && !isFetching) {
      const conversationId = conversation?.referenceId

      setLocalStorage('calendarParams', {
        view: view ?? 'day',
        date,
        sideNav: 'sms',
        clinicReferenceId,
      })

      if (conversationId) {
        history.push(`/calendar/${view ?? 'day'}/${date}/sms/${clinicReferenceId}?conversation=${conversationId}`)
      } else {
        const firstName = selectedAppointment?.patient?.firstName ?? selectedPatient?.firstName ?? ''
        const lastName = selectedAppointment?.patient?.lastName ?? selectedPatient?.lastName ?? ''
        const contactPhoneNumber = selectedAppointment?.patient?.phoneNumber ?? selectedPatient?.phoneNumber ?? ''
        const conversation = {
          firstName,
          lastName,
          contactPhoneNumber,
        }

        createConversationDispatch(startCreateConversation(conversation))

        history.push(`/calendar/${view ?? 'day'}/${date}/sms/${clinicReferenceId}`)
      }

      setSelectedTab(TabNames.SMS)
    }
  }, [selectedAppointment, isFetching, selectedPatient])

  const guardedTabName = () => {
    return switchFn(selectedTabFromUrl)
      .on(
        (x: string) => x === 'sms',
        () => (conversationsEnabled ? TabNames.SMS : TabNames.PATIENTS)
      )
      .on(
        (x: string) => x === 'calls',
        () => (callsEnabled ? TabNames.CALLS : TabNames.PATIENTS)
      )
      .on(
        (x: string) => x === 'todo_list',
        () => (featureFlags.enableTodoListTab ? TabNames.TODO_LIST : TabNames.PATIENTS)
      )
      .on(
        (x: string) => x === 'open_appointments',
        () => TabNames.OPEN_APPOINTMENTS
      )
      .otherwise(() => TabNames.PATIENTS)
  }

  const selectedEmployeesLength = openAppointmentsEmployeesContext.filteredSelectedEmployeeReferenceIds.length
  const canFilterByEmployees =
    !openAppointmentsEmployeesContext.isAllSelected || !!openAppointmentsEmployeesContext.searchedEmployeeString

  const PractitionersFiltersButton = useCallback(
    (props: ButtonProps) => (
      <S.StyledFilterButton size="sm" fullWidth variant="bordered" {...props} startIcon={<EmployeeIcon />}>
        <S.StyledBadge badgeContent={selectedEmployeesLength}>{t('employees')}</S.StyledBadge>
      </S.StyledFilterButton>
    ),
    []
  )

  // running exp only with Kudamm-Clinic in prod
  // on staging, preview and dev env, we always show smart recorder link icon
  const ifProductionAndKudamClinicAndSmartVoiceIsEnabled =
    window.env.REACT_APP_ENV === 'production' &&
    selectedClinic?.referenceId === 'd9b83c25-51b5-47f2-a53d-7e563c5884f7' &&
    featureFlags.enableSmartVoiceLink

  const ifNotProductionAndSmartVoiceIsEnabled =
    window.env.REACT_APP_ENV !== 'production' && featureFlags.enableSmartVoiceLink

  const renderSmartVoiceRecorderLinkIcon =
    ifProductionAndKudamClinicAndSmartVoiceIsEnabled || ifNotProductionAndSmartVoiceIsEnabled

  return (
    <UiLibraryThemeProvider>
      <div className={clsx(classes.root, { [classes.closed]: !isOpen })}>
        <TabContext value={guardedTabName()}>
          <Tabs onChange={handleTabChange} value={selectedTab}>
            {TABS.map(({ icon, testId, value }) => (
              <Tab icon={icon} data-testid={testId} value={value} key={value} />
            ))}
          </Tabs>

          <TabPanel value={TabNames.PATIENTS}>
            <SuspendedComponent>
              <WaitingRoomStatic />
            </SuspendedComponent>
          </TabPanel>

          <TabPanel value={TabNames.SMS}>
            <SuspendedComponent>
              <ConversationManagement />
            </SuspendedComponent>
          </TabPanel>
          <TabPanel value={TabNames.CALLS}>
            <div className={classes.callManagementWrapper}>
              <CallManagement
                baseURL={window.env.REACT_APP_OPERATOR_ENDPOINT ?? ''}
                auth={token}
                clinicReferenceId={selectedClinic?.referenceId}
                labels={callManagementLabels}
                onCallCardClick={handleCallCardClick}
                onNewAppointmentClick={handleNewAppointmentClick}
                showIncomingCalls={isIncomingCallsEnabled}
                showAnonymous
                isObfuscated={isObfuscated}
                {...(isIncomingCallsEnabled && {
                  onNewAppointmentClick: handleNewAppointmentClick,
                })}
                smartVoiceRecorderLink={
                  renderSmartVoiceRecorderLinkIcon ? window.env.REACT_APP_SMART_VOICE_LINK : undefined
                }
              />
            </div>
          </TabPanel>
          <TabPanel value={TabNames.TODO_LIST}>
            <TodoList
              baseURL={window.env.REACT_APP_TODO_LIST_ENDPOINT ?? ''}
              phoneBaseUrl={window.env.REACT_APP_OPERATOR_ENDPOINT ?? ''}
              auth={token}
              clinicReferenceId={selectedClinic?.referenceId}
              lang={i18n.language}
              labels={todoTabLabels}
              clinicHasPhonesIntegrations={!!callsEnabled}
              onOpenPatientDetails={handleCallCardClick}
              onPatientCall={handleCallPatientClick}
              onOpenInCalendar={handleOpenInCalendar}
              isObfuscated={isObfuscated}
            />
          </TabPanel>
          <TabPanel value={TabNames.OPEN_APPOINTMENTS}>
            <div className={classes.openAppointmentsWrapper}>
              <Box mb={1}>
                <Button onClick={handleOpenAppointmentDialog} fullWidth variant="bordered">
                  {`+ ${t('add_new_open_appointment')}`}
                </Button>
              </Box>
              <Box mb={1}>
                <PractitionerDropdown context={openAppointmentsEmployeesContext} Button={PractitionersFiltersButton} />
              </Box>
              <OpenAppointmentsList
                auth={token}
                clinicReferenceId={selectedClinic?.referenceId}
                echoCoreBaseURL={window.env.REACT_APP_CORE_APP_ENDPOINT}
                patientBaseURL={window.env.REACT_APP_PATIENT_ENDPOINT}
                calmasterBaseURL={window.env.REACT_APP_CALMASTER_ENDPOINT}
                onPatientClick={handlePatientClick}
                labels={openAppointmentsLabels}
                language={currentLanguage as Language}
                employees={filteredSelectedEmployees}
                onClose={handleAppointmentDialogClose}
                isObfuscated={isObfuscated}
                ref={openAppointmentsRef}
                {...(canFilterByEmployees && {
                  employeeReferenceIds: openAppointmentsEmployeesContext.filteredSelectedEmployeeReferenceIds,
                })}
                {...(!!callsEnabled && {
                  operatorBaseURL: window.env.REACT_APP_OPERATOR_ENDPOINT ?? '',
                })}
                {...(!!conversationsEnabled && { onMessageOpen: handleOpenSmsTab })}
              />
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </UiLibraryThemeProvider>
  )
}
