import { colors } from '@dentalux/ui-library-core'

import { makeStyles } from '@material-ui/core'

import styled from '@emotion/styled'
import { Badge, Button, badgeClasses, buttonClasses } from '@mui/material'

export const sideNavWidth = 364

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      left: 0,
      top: 64,
      width: sideNavWidth,
      height: 'calc(100vh - 64px)', // 64px is the height of the header
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      padding: 8,
      boxSizing: 'border-box',

      '& > *': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        backgroundColor: '#001429',
        opacity: 0,
        transition: '200ms ease opacity 200ms',
        zIndex: 2,
      },

      '& .MuiTabPanel-root': {
        height: 'calc(100% - 56px)', // 48px is the height of the tabs + 8px padding top
        padding: theme.spacing(1, 2),
        backgroundColor: colors.aqua[50],
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        boxSizing: 'border-box',
      },

      '& .MuiTabs-root': {
        boxSizing: 'border-box',
        padding: 0,

        '& .MuiTab-root': {
          minWidth: 'auto',
          width: '100%',
          flexShrink: 1,
        },
      },
    },

    callManagementWrapper: {
      overflow: 'auto',
      height: '100%',
    },

    openAppointmentsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    closed: {
      zIndex: -1,

      '&:after': {
        width: '100%',
        height: '100%',
        opacity: 0.3,
        transition: '200ms ease opacity',
      },
    },

    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(256, 59, 68, 1)',
      },

      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 7px rgba(256, 59, 68, 0.2)',
      },

      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(256, 59, 68, 0)',
      },
    },

    '@keyframes icon': {
      '0%': { transform: 'translate3d(0em, 0, 0)' },
      '2%': { transform: 'translate3d(0.01em, 0, 0)' },
      '4%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '6%': { transform: 'translate3d(0.01em, 0, 0)' },
      '8%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '10%': { transform: 'translate3d(0.01em, 0, 0)' },
      '12%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '14%': { transform: 'translate3d(0.01em, 0, 0)' },
      '16%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '18%': { transform: 'translate3d(0.01em, 0, 0)' },
      '20%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '22%': { transform: 'translate3d(0.01em, 0, 0)' },
      '24%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '26%': { transform: 'translate3d(0.01em, 0, 0)' },
      '28%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '30%': { transform: 'translate3d(0.01em, 0, 0)' },
      '32%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '34%': { transform: 'translate3d(0.01em, 0, 0)' },
      '36%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '38%': { transform: 'translate3d(0.01em, 0, 0)' },
      '40%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '42%': { transform: 'translate3d(0.01em, 0, 0)' },
      '44%': { transform: 'translate3d(-0.01em, 0, 0)' },
      '46%': { transform: 'translate3d(0em, 0, 0)' },
    },

    ringingBackground: {
      backgroundColor: '#FF453A',
      borderRadius: '100%',
      position: 'absolute',
      right: 1,
      top: 4,
      width: 20,
      height: 20,
      padding: '2px 1px 1px 1px',
      animation: '$pulse 2000ms infinite',
    },

    ringing: {
      color: '#fff',
      width: 16,
      height: 16,
      animation: '$icon 3000ms infinite',
    },

    overflowVisible: {
      overflow: 'visible',
    },

    logoText: {
      background: '-webkit-linear-gradient(156.11deg, #51BFB9 15.35%, #24A19A 95.94%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
      color: '#24A19A',
    },
  }),
  { name: 'SideNav' }
)

export const StyledFilterButton = styled(Button)`
  border-radius: 8px;
  text-transform: none;
  color: ${colors.grey[800]};
  text-align: left;
  font-size: 14px;
  border: none;

  .${buttonClasses.endIcon} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

export const StyledBadge = styled(Badge)`
  .${badgeClasses.badge} {
    position: relative;
    transform: none;
    background-color: ${colors.aqua[75]};
    margin-left: 4px;
  }
`
